<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title>
          <span
            id="resetpwd_title"
            v-translate='{ caller: callerName }'
          >Reset password for: "%{ caller }"</span>
        </v-card-title>

        <v-card-text>
          <v-radio-group v-model="resetMethod">
            <v-radio
              selected
              key="resetLink"
              id="resetLink"
              name="resetLink"
              :label="$gettext('Send the caller a self-service reset email')"
              value="link"
            ></v-radio>
            <v-radio
              key="resetTemp"
              id="resetTemp"
              name="resetTemp"
              :label="featureFlags.passwordResetType !== 'PASSPHRASE' ? $gettext('Use a randomly generated temporary password') : $gettext('Use a randomly generated temporary passphrase')"
              value="temp"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Confirmation button for the reset dialog -->
          <v-btn
            id="reset_confirm"
            color="success"
            @click="resetPassword()"
            :loading="resetInProgress"
          >
            <translate>Confirm</translate>
          </v-btn>

          <!-- Cancel button for the reset dialog -->
          <v-btn
            id="reset_cancel"
            color="primary"
            @click="resetMethod = 'link'; dialog = false;"
            :disabled="resetInProgress"
          >
            <translate>Cancel</translate>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <TempPassword
      v-model=" dialogTempPassword "
      :profile=" profile "
      :tempPassword=" tempPassword "
      :featureFlags=" featureFlags "
      @close=" closeAndClearTempPassword "
    />
  </v-row>
</template>

<script>
import TempPassword from '@/components/ActionDialogs/TempPassword';

export default {
  name: 'ResetPassword',

  components: {
    TempPassword
  },

  // Component properties
  //
  // profile - project object for the selected user.
  // value   - controls if the dialog is visible or hidden.
  //
  props: {
    profile: Object,
    value: Boolean,
    featureFlags: Object
  },
  computed: {
    // expose a computed dialog parameter that returns the :value property
    // of this component on retrieval and emits an close event to the parent
    // to close the dialog when set to 'false'.
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('close', 'reset-password');
        }
      }
    },
    // FIXME: we should make this a return property from our API call so we don't have to do this.
    callerName() {
      if (this.profile && this.profile.profile) {
        return this.profile.profile.firstName + ' ' + this.profile.profile.lastName;
      }
      else {
        return "";
      }
    }
  },
  data: () => ({
    // control spinner and availability to buttons when reset request is in progress.
    resetInProgress: false,

    dialogTempPassword: false,

    // attributes for the password reset form
    resetMethod: 'link',

    tempPassword: ''
  }),

  methods: {
    async resetPassword() {
      // bail out of the component wasn't initialized properly
      if (this.profile === null || this.profile.id === null) {
        return;
      }

      // startup a spinner for the unlock action.
      this.resetInProgress = true;

      const {status, notification, tempPass} = await this.$cvApi.resetPassword(this.profile.id, this.resetMethod);

      if(status) {
        if(this.resetMethod == 'link') {
          // caller is now in recovery status, so update the search results.
          this.$emit('updateStatus', { profile: this.profile, status: 'RECOVERY'});
        }
        else {
          // caller is now in expired status, so update the search results.
          this.$emit('updateStatus', { profile: this.profile, status: 'PASSWORD_EXPIRED'});

          // Open the temporary password dialog
          if (tempPass) {
            this.tempPassword = tempPass;
            this.dialogTempPassword = true;
          }
        }
      }

      // if we have a notification (either successful or failed -- emit it)
      if(notification) {
        this.$emit('notify', notification);
      }

      // Stop the processing indicator, close the dialog, and reset the method
      this.resetInProgress = false
      this.resetMethod = 'link';
      this.dialog = false;
    },
    closeAndClearTempPassword() {
      this.tempPassword = '';
      this.dialogTempPassword = false;
    }
  }
}
</script>
