import { OktaAuth } from '@okta/okta-auth-js'
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import GetTextPlugin from 'vue-gettext';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'

import { CallerVerifyAPI } from '@/api/callerVerify.api';
import cvutils from '@/cvutils/cvutils';
import vuetify from '@/plugins/vuetify';

import translations from '../public/translations.json'
import App from './App.vue'
import OktaVue from './components/OktaVue'
import router from './router'



export class CallerVerify {
  // CallerVerify Object Constructor
  constructor(options) {

    // api location and config overrides (if specified)
    this.ApiTimeout = options.timeout;
    this.ApiBaseURL = options.baseURL;

    // if not input base url was specified, see if we have a baked in base url and use that.
    if(!options.baseURL && process.env.VUE_APP_ROOT_API && process.env.VUE_APP_ROOT_API !== "") {
      this.ApiBaseURL = process.env.VUE_APP_ROOT_API;
    }

    this.renderEL = options.renderEL || '#app';
    this.widget = options.widget || false;
    this.disableLookup = options.disableLookup || false;

    // optional callback function on successful verification.
    this.verifyCBFun = options.verifyCBFun;

    // auto-lookup of caller base on input data.
    this.lookupField = options.lookupField;
    this.lookupCaller = options.lookupCaller;

    this.oktaAuth = undefined;
  }

  // public method to start the initialization of the CV Vue app.
  async initialize() {

    // attempt to use the baseURL to get our base config data.
    Vue.prototype.$cvApi = new CallerVerifyAPI(this.ApiBaseURL, this.ApiTimeout);
    const {status, settings} = await Vue.prototype.$cvApi.getBaseConfig();
    this.config = settings;

    // pass along if we should render in widget mode or not.
    this.config.widget = this.widget;

    // if there was a error getting the base config, make note of that.
    if(!status) {
      this.config.initializeError = true;
      console.error("Failed to parse initialization data.");
    }

    Vue.config.devtools = process.env.NODE_ENV === 'development';
    Vue.config.productionTip = false;

    this.config.verifyCBFun = this.verifyCBFun;
    this.config.lookupField = this.lookupField;
    this.config.lookupCaller = this.lookupCaller;
    this.config.disableLookup = this.disableLookup;

    // Store our config globally, there is probably a better solution for this.
    Vue.prototype.$appConfig = this.config;


    this.oktaAuth = new OktaAuth({
      clientId: this.config.client_id,
      redirectUri: window.location.origin + '/login/callback',
      issuer: this.config.issuer,
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      pkce: true
    });

    Vue.use(OktaVue, { oktaAuth: this.oktaAuth, cvApi: Vue.prototype.$cvApi });
    Vue.prototype.$cvApi.setAuthManager(Vue.prototype.$auth);

    const CVUtilsPlugin = {
      install() {
        Vue.cvutils = cvutils
        Vue.prototype.$cvutils = cvutils
      }
    }
    Vue.use(CVUtilsPlugin)

    Vue.use(VueCookies);
    Vue.use(VueTelInputVuetify, {vuetify})
    Vue.use(GetTextPlugin, {
      availableLanguages: {
        en: 'English',
        fr: 'Français',
      },
      defaultLanguage: navigator.language.substring(0,2),
      translations: translations}
    );

    this.app = new Vue({
      router,
      vuetify,
      render: h => h(App)
    });

    this.app.$mount(this.renderEL);
  }

  async destroy() {
    // if we have an oktaAuth object make sure we stop the background services.
    if(this.oktaAuth) {
      this.oktaAuth.stop();
    }

    // clean up app specific services and threads.
    if(this.app) {
      this.app.$destroy();
    }
  }

}