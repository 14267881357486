<template>
  <v-menu
      bottom
      offset-y
    >
      <template v-slot:activator="{on, attrs, value}">
        <v-list-item
          id="cv_language"
          v-on="on"
          v-bind="attrs"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-translate</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ $language.available[selectedItem] }}
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-if="value">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
      <v-list>
        <v-list-item-group mandatory v-model="selectedItem" color="primary">
          <v-list-item v-for="(language, key) in $language.available" :key="key" :value="key" @click="changeLanguage(key)">
            <v-list-item-title>{{ language }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
</template>

<script>
export default {
  name: 'LanguageSelector',
  data: () => ({
    selectedItem: 'en',
  }),
  methods: {
    changeLanguage: function(language) {
      // change both the vuetify language as well as the vue-gettext language.
      this.$vuetify.lang.current = language
      this.$root.$language.current = language

      // language overridden, set a cookie to keep track fo this.
      this.$cookies.set('preferredLang', language);
    }
  },
  mounted() {
    // Load the preferred language from cookie (if available)
    if (this.$cookies.get('preferredLang')) {
      const preferredLang = this.$cookies.get('preferredLang');
      this.selectedItem = preferredLang
      this.changeLanguage(preferredLang)
    }
    else {
      // no cookie use whatever the browser language for the language selector
      this.selectedItem = this.$root.$language.current
    }
  }
};
</script>

<style scoped>
</style>