<template>
  <v-footer
    app
    height="auto"
    color="secondary"
  >
    <v-row
      class="my-0"
      align-content="center"
      justify="center"
    >
      <v-col class="white--text text-center text-sm-left mx-sm-0 mt-sm-0 pr-sm-0 flex-grow-0 text-no-wrap">
        Caller Verify v{{ vue_version }} &copy; 2020-2023<span class="white-space hidden-xs-only"> - </span>
      </v-col>

      <v-col class="white--text text-center text-sm-left mx-sm-0 mt-sm-0 pl-sm-0 text-no-wrap">
        TechJutsu Inc. - <translate>Patent Pending</translate>
      </v-col>

    </v-row>
  </v-footer>
</template>

<script>

export default {
  name: 'CallerVerifyFooter',

  data: () => ({
    vue_version: process.env.VUE_APP_VERSION
  }),
};
</script>

<style scoped>
.white-space {
  white-space: pre;
}

@media (max-width: 600px) {
  .col {
    margin: 0 auto;
  }
}
</style>
