<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title>
          <span id="unlock_title" v-translate='{caller: callerName}'>Unlock profile for: "%{ caller }"</span>
        </v-card-title>

        <v-card-text>
          <translate>Are you sure you want to unlock the selected profile?</translate>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Confirmation button for the unlock dialog -->
          <v-btn
            id="unlock_confirm"
            color="success"
            @click="unlockUser()"
            :loading="unlockInProgress"
          >
            <translate>Confirm</translate>
          </v-btn>

          <!-- Cancel button for the unlock dialog -->
          <v-btn
            id="unlock_cancel"
            color="primary"
            @click="$emit('close', 'unlock')"
            :disabled="unlockInProgress"
          >
            <translate>Cancel</translate>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'UnlockUser',

  // Component properties
  //
  // profile - project object for the selected user.
  // value   - controls if the dialog is visible or hidden.
  //
  props: {
    profile: Object,
    value: Boolean
  },
  computed: {
    // expose a computed dialog parameter that returns the :value property
    // of this component on retrieval and emits an close event to the parent
    // to close the dialog when set to 'false'.
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if(!value) {
          this.$emit('close', 'unlock');
        }
      }
    },
    // FIXME: we should make this a return property from our API call so we don't have to do this.
    callerName() {
      if(this.profile && this.profile.profile) {
        return this.profile.profile.firstName + ' ' + this.profile.profile.lastName;
      }
      else {
        return "";
      }
    }
  },
  data: () => ({
    // control spinner and availability to buttons when unlock request is in progress.
    unlockInProgress: false
  }),

  methods: {
    async unlockUser() {
      // bail out of the component wasn't initialized properly
      if (this.profile === null || this.profile.id === null) {
        return;
      }

      // startup a spinner for the unlock action.
      this.unlockInProgress = true;

      const {status, notification} = await this.$cvApi.unlockProfile(this.profile.id);

      if(status) {
        // update the callers status in parent component
        this.$emit('updateStatus', {profile: this.profile, status: 'ACTIVE'})
      }

      // display error notification to the user
      this.$emit('notify', notification);

      // Stop the processing indicator and close the dialog
      this.unlockInProgress = false;
      this.dialog = false;
    }
  }
}
</script>
