import axios from 'axios';

const apiVersion= 'api/v2';
const apiTimeoutDefault = 25000; // 25 sec default timeout.

// Wrapper class for making HTTP requests to CallerVerify backend.
export class httpClient {
    constructor(baseURL = null, timeout = null, auth = null) {
        this.baseURL = baseURL || (window.location.hostname === 'localhost' ? `http://localhost:5000` : `https://${window.location.hostname}`)

        // create axios client
        this.$axiosClient = axios.create({
            baseURL: `${this.baseURL}/${apiVersion}`,
            timeout: timeout
        });

        // access and refresh token logic lives in here.
        this.$auth = auth;
        this.timeout = timeout || apiTimeoutDefault;
    }

    setAuthManager(auth) {
        this.$auth = auth;
    }

    async get(url, _timeout = null, skip_auth = false) {
        try {
            // override timeout (if requested)
            const config = {timeout: _timeout || this.timeout};

            // make sure we have a valid access token (refreshing if needed.)
            if(!skip_auth) {
                this.$axiosClient.defaults.headers.common['Authorization'] = `Bearer ${this.getAccessToken()}`;
            }

            // issue the get request waiting for the result.
            const { data, status } = await this.$axiosClient.get(url, config);
            return {error: null, status_code: status, data: data}
        }
        catch(error) {
            // return error details.
            return {error: error, status_code: error.response?.status, data: error.response?.data}
        }
    }

    async post(url, body, _timeout = null, skip_auth = false) {
        try {
            // override timeout (if requested)
            const config = {timeout: _timeout || this.timeout};

            // make sure we have a valid access token (refreshing if needed.)
            if(!skip_auth) {
                this.$axiosClient.defaults.headers.common['Authorization'] = `Bearer ${this.getAccessToken()}`;
            }

            // issue the post request, waiting for the result.
            const {data, status} = await this.$axiosClient.post(url, body, config);
            return {error: null, status_code: status, data: data}
        }
        catch(error) {
            // return error details.
            return {error: error, status_code: error.response?.status, data: error.response?.data}
        }
    }

    async delete(url, _timeout = null, skip_auth = false) {
        try {
            // override timeout (if requested)
            const config = {timeout: _timeout || this.timeout};

            // make sure we have a valid access token (refreshing if needed.)
            if(!skip_auth) {
                this.$axiosClient.defaults.headers.common['Authorization'] = `Bearer ${this.getAccessToken()}`;
            }

            // issue the post request, waiting for the result.
            const {data, status} = await this.$axiosClient.delete(url, config);
            return {error: null, status_code: status, data: data}
        }
        catch(error) {
            // return error details.
            return {error: error, status_code: error.response?.status, data: error.response?.data}
        }
    }

    getAccessToken() {
        if (this.$auth) {
            return this.$auth.getAccessToken();
        }
        else {
            return '';
        }
    }

}
