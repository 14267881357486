import AdminConfig from '@pages/Admin/Config'
import ErrorPage from '@pages/Error/ErrorPage'
import LoginPage from '@pages/Login/LoginPage'
import UsageReports from '@pages/Reports/UsageReports'
import CallerVerify from '@pages/Verify/CallerVerify'
import Vue from 'vue'
import Router from 'vue-router'

import { LoginCallback } from '../components/OktaVue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: LoginPage
    },
    {
      path: '/login/callback',
      component: LoginCallback
    },
    {
      path: '/error',
      component: ErrorPage
    },
    {
      path: '/verify',
      component: CallerVerify,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/config',
      component: AdminConfig,
      meta: {
        requiresAuth: true,
        requiresAcl: ['admin']
      }
    },
    {
      path: '/reports',
      component: UsageReports,
      meta: {
        requiresAuth: true,
        requiresAcl: ['reporting']
      }
    },
    // redirect all unspecified routes back to /
    {
      path: '*',
      redirect: '/',
    }
  ]
})

export default router
