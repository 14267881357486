<template>
  <v-navigation-drawer
    v-if="authState && authState.isAuthenticated && profile && profile.name"
    permanent
    app
    clipped
    expand-on-hover
    mini-variant
  >
    <v-list nav>
      <v-list-item-group
      >
      <v-list-item
        id="cv_okta_verify_caller"
        v-if="profile && profile.name"
        link
        to="/verify"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-check</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <translate key="verify_caller">Verify Caller</translate>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        id="cv_okta_usage_reports"
        v-if="profile && profile.name && settings.permission && settings.permission.includes('reporting')"
        link
        to="/reports"
      >
        <v-list-item-icon>
          <v-icon>mdi-chart-line</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <translate key="usage_reports">Usage Reports</translate>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        id="cv_admin_config"
        v-if="profile && profile.name && settings.permission && settings.permission.includes('admin')"
        link
        to="/config"
      >
        <v-list-item-icon>
          <v-icon>mdi-cog-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <translate key="admin_config">Configuration</translate>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      </v-list-item-group>
      <v-list-item
        id="cv_okta_admin_link"
        v-if="profile && profile.name && settings.permission && settings.permission.includes('read-write')"
        link
        target="_blank"
        :href="settings.oktaOrgUri + '/home/admin-entry?fromURI=/admin/users'"
      >
        <v-list-item-icon>
          <v-icon>mdi-launch</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <translate key="okta_admin_portal">Okta Admin Portal</translate>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>
</template>

  <script>
    export default {
      name: 'CallerVerifyNavigation',

      props: {
        profile: Object,
        settings: Object
      },
      data: () => ({

      }),
      computed: {
      },
      methods: {
      }
    }
  </script>
