<template>
  <!-- MFA Enrollment Dialog -->
  <v-row justify="center">
      <v-dialog v-model="dialogMFAEnroll" persistent max-width="625">
      <v-card>
          <v-card-title>
          <span v-translate='{caller: callerName, factor: factorDescription}'>
              Enroll %{ factor } for: "%{ caller }"
          </span>
          </v-card-title>

          <div class="mb-5 pa-5">
          <v-container>
              <v-row dense>
              <v-col>
                  <label
                  v-if="factorType === 'sms'"
                  v-translate='{factor: factorDescription}'
                  >
                  Enter the phone number to enroll in %{ factor }:
                  </label>
                  <div>
                  <label
                      v-if="factorType === 'email'"
                      v-translate='{factor: factorDescription}'
                  >
                  The following email will be used to enroll in %{ factor }:
                  </label>
                  </div>
              </v-col>
              </v-row>
              <v-row dense align="center">
              <v-col cols="8" class="pt-8">
                  <vue-tel-input-vuetify
                  :inputId="'enroll_phonenumber'"
                  :inputvalue="enrollmentPhoneNumber"
                  v-if="factorType === 'sms'"
                  v-model="enrollmentPhoneNumber"
                  outlined
                  dense
                  :defaultCountry="'CA'"
                  :onlyCountries="['CA', 'US']"
                  :selectLabel="$gettext('Country')"
                  autocomplete="off"
                  required
                  :placeholder="$gettext('Enter a phone number')"
                  :label="$gettext('Enter a phone number')"
                  appendIcon="$dropdown"
                  @input="onPhoneInput"
                  :disabled="enrollmentChallengeSent"
                  ></vue-tel-input-vuetify>
                  <v-text-field
                  id="enroll_emailaddress"
                  :inputvalue="selectedCustomerEmailDisplay"
                  v-if="factorType === 'email'"
                  v-model="selectedCustomerEmailDisplay"
                  dense
                  autocomplete="off"
                  required
                  outlined
                  :readonly="true"
                  ></v-text-field>
              </v-col>
              <v-col cols="4">
                  <v-btn
                  id="enroll_sendcode"
                  color="primary"
                  @click="enrollFactor(true)"
                  :disabled="forceEnrollment ||
                              (factorType === 'sms' && !enrollPhone.valid) ||
                              (factorType === 'email' && !enrollEmailValid) ||
                              enrollmentChallengeSent"
                  >
                  {{ enrollmentSendButton }}
                  </v-btn>
              </v-col>
              </v-row>
              <v-row dense class="justify-end">
              <v-col cols="8">
                  <v-alert id="enroll_timeout" v-if="enrollmentChallengeTimeout"
                  type="warning"
                  colored-border
                  border="bottom"
                  elevation="2"
                  dismissible
                  >
                  <translate>Haven't received Code? To try again, click Re-send code.</translate>
                  </v-alert>
              </v-col>
              <v-col cols="4">
                  <v-checkbox
                  id="enroll_force"
                  v-model="forceEnrollment"
                  dense
                  :label="$gettext('Force Enrollment')"
                  @click="callerResponse = ''"
                  ></v-checkbox>
              </v-col>
              </v-row>
          </v-container>
          <v-expand-transition>
              <div v-if="enrollmentChallengeExpand && !forceEnrollment">
              <v-divider></v-divider>
              <v-container>
                  <v-row dense>
                  <v-col>
                      <label><translate>Confirmation code sent to caller:</translate></label>
                  </v-col>
                  </v-row>
                  <v-row dense>
                  <v-col>
                      <v-text-field
                      v-model="callerResponse"
                      name="callerResponse"
                      :label="'Confirmation Code'"
                      id="enrollResponse"
                      ref="callerResponse"
                      outlined
                      color="#333"
                      autocomplete="off"
                      :counter="responseCount"
                      :clearable="true"
                      :type="'text'"
                      :maxlength="responseCount"
                      :hint="responseHint"
                      :rules="otpRules"
                      @keyup.enter="forceEnrollment ? enrollFactor(false) : enrollFactorConfirm()"
                      ></v-text-field>
                      <div id="cv_enroll_msg" v-if="callerEnrolled !== null">
                      {{ enrollmentMessage }}
                      <svg v-if="callerEnrolled !== null && !callerEnrolled"  width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-x-circle-fill float-right mr-3 error--text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg>
                      </div>
                  </v-col>
                  </v-row>
              </v-container>
              </div>
          </v-expand-transition>
          </div>

          <v-divider></v-divider>

          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              id="enroll_confirm"
              color="success"
              @click="forceEnrollment ? enrollFactor(false) : enrollFactorConfirm()"
              :loading="mfaEnrollInProgress"
              :disabled="!((!responseInvalid) ||
                          (forceEnrollment && factorType === 'sms' && enrollPhone.valid) ||
                          (forceEnrollment && factorType === 'email' && enrollEmailValid))"
          >
              <translate>Confirm</translate>
          </v-btn>
          <v-btn
              id="enroll_cancel"
              color="primary"
              @click="resetComponentData(); dialogMFAEnroll = false"
              :disabled="mfaEnrollInProgress"
          >
              <translate>Cancel</translate>
          </v-btn>
          </v-card-actions>
      </v-card>
      </v-dialog>
  </v-row>
</template>

<script>
import {translate} from 'vue-gettext';
const {gettext: $gettext, gettextInterpolate} = translate;

export default {
  name: 'EnrollMFA',

  // Component properties
  //
  // profile - project object for the selected user.
  // value   - controls if the dialog is visible or hidden.
  //
  props: {
    profile: Object,
    factor: Object,
    featureFlags: Object,
    value: Boolean
  },

  computed: {
    // expose a computed dialog parameter that returns the :value property
    // of this component on retrieval and emits an close event to the parent
    // to close the dialog when set to 'false'.
    dialogMFAEnroll: {
      get() {
        return this.value;
      },
      set(value) {
        if(!value) {
          this.$emit('close', 'mfa-enroll');
        }
      }
    },
    factorDescription() {
      return this.$cvutils.getTranslatedFactorDesc(this.factor);
    },
    factorType() {
      return this.factor?this.factor.type:'';
    },
    // FIXME: we should make this a return property from our API call so we don't have to do this.
    callerName() {
      if(this.profile && this.profile.profile) {
        return this.profile.profile.firstName + ' ' + this.profile.profile.lastName;
      }
      else {
        return "";
      }
    },
    enrollEmailValid() {
      if (this.profile !== null && this.profile.profile && this.profile.profile.email !== null) {
        return true;
      }
      return false;
    },
    // FIXME: this is duplicated in Verify.vue - look for a way to share this function.
    //
    // computed property to determine if the Verify button should be
    // disabled or enabled.
    responseInvalid() {
      // make sure we have some sort of response at a minimum
      if(this.callerResponse && this.callerResponse.length > 0) {
        // if this.responseCount is not set, we are dealing with a security question
        // so long as we have one character, let it through.
        if(!this.responseCount) {
          return false; // button is not disabled (enabled)
        }
        // otherwise, make sure we have at least responseCount characters.
        else if(parseInt(this.responseCount) === this.callerResponse.length) {
          // finally make sure they are all numbers.
          return !(/^([0-9]{6})$/.test(this.callerResponse))
        }
      }
      return true;
    },
    // display the enrollment email, but respect the obfuscation setting.
    selectedCustomerEmailDisplay() {
      let displayEmail = this.selectedCustomerEmail;
      if(this.featureFlags.obfuscateMFACredIDs) {
        // for enrollment purposes hide the email if configured.
        let components = displayEmail.split('@');
        if(components[0].length <= 2) {
          displayEmail = '..@' + components[1];
        }
        else {
          displayEmail = components[0].charAt(0) + '..' + components[0].charAt(components[0].length - 1) + '@' + components[1];
        }
      }
      return displayEmail;
    },
    selectedCustomerEmail() {
      if(this.profile && this.profile.profile && this.profile.profile.email) {
        return this.profile.profile.email;
      }
      return $gettext('No primary email configured, unable to enroll.');
    },
  },
  data: () => ({

    // component data.
    responseCount: 6,
    responseHint: '',


    forceEnrollment: false,
    enrollmentChallengeSent: false,
    enrollmentTimeoutId: null,
    enrollmentChallengeExpand: false,
    enrollmentChallengeTimeout: false,
    mfaEnrollInProgress: false,
    enrollmentMessage: null,
    callerEnrolled: null,
    callerResponse: null,
    enrollmentSendButton: $gettext('Send code'),
    enrollmentPhoneNumber: '',
    enrollPhone: {
      number: '',
      valid: false,
      country: undefined
    },

    otpRules: [
      v => (!v || /^([0-9])*$/.test(v)) || $gettext('Code must contain numbers only.')
    ],

  }),
  methods:  {
    onPhoneInput(formattedNumber, {number, valid, country}) {
      this.enrollPhone.number = number.international;
      this.enrollPhone.valid = valid;
      this.enrollPhone.country = country.name;
    },
    clearEnrollmentTimeout() {
      if(this.enrollmentTimeoutId) {
        clearTimeout(this.enrollmentTimeoutId);
      }

    },
    // helper method to reset all the component data to an initial state.
    resetComponentData() {
      // stop the enrollment timeout thread.
      this.clearEnrollmentTimeout();

      this.responseCount = 6;
      this.callerResponse = null;
      this.callerEnrolled = null;
      this.enrollmentMessage = null
      this.responseHint = '';

      this.forceEnrollment = false;
      this.enrollmentChallengeSent = false;
      this.enrollmentTimeoutId = null;
      this.enrollmentSendButton = $gettext('Send code');
      this.enrollmentChallengeExpand = false;
      this.enrollmentChallengeTimeout = false;

      this.enrollmentPhoneNumber = '';
      this.enrollPhone.number = '';
      this.enrollPhone.valid = false;
      this.enrollPhone.countr = undefined;
    },
    async enrollFactor(isSendButton) {
      this.mfaEnrollInProgress = false;
      let bypassConfirmation = false;

      // confirmation button was pressed
      if(!isSendButton) {
        // put a spinner on the confirm while its in progress.
        this.mfaEnrollInProgress = true;
      }
      // otherwise sent button was pressed.
      else {
        // disable the button and change its name to sent.
        this.enrollmentChallengeSent = true;
        this.enrollmentSendButton = $gettext('Sent')
      }

      const userId = this.profile.id;
      let factorUri = null;
      const factorType = this.factor.type;
      const factorProvider = this.factor.provider;

      // set the factor uri based on the type.
      if(factorType === 'sms') {
        factorUri = this.enrollPhone.number;
      }
      else if (factorType === 'email') {
        factorUri = this.selectedCustomerEmail;
      }

      const {status, notification, factor} = await this.$cvApi.factorEnroll(userId, factorType, factorProvider, factorUri, this.forceEnrollment);

      if(status && factor) {
        if(factor.status === 'ACTIVE' && factorType === 'sms' && !this.forceEnrollment) {
          bypassConfirmation = true;
        }
        else if(factor.status === 'PENDING_ACTIVATION') {
          this.enrollmentChallengeTimeout = false;
          this.enrollmentChallengeExpand = true; // enable the confirmation box.
          this.enrollmentFactorId = factor.id; // used for confirmation.

          if(factorType === 'sms') {
            const translated = $gettext('A 6 digit code has been sent to the caller via SMS to: %{phone_number}')
            this.responseHint = gettextInterpolate(translated, {phone_number: factor.profile.phone_number})
          }
          else if(factorType === 'email') {
            const translated = $gettext('A 6 digit code has been emailed to the caller at: %{email}')
            this.responseHint = gettextInterpolate(translated, {email: factor.profile.email})
          }
          // setup a timer event to re-enable the button and phone field after 30 seconds.
          this.enrollmentTimeoutId = setTimeout(() => {
            // clear the enrollmentChallengeSent to allow changes and re-send.
            this.enrollmentTimeoutId = null;
            this.enrollmentChallengeSent = false;
            this.enrollmentChallengeTimeout = true;
            this.enrollmentSendButton = $gettext('Re-send code');
          }, 30000);
        }

        // notify the parent component to refresh the factors from the backend.
        this.$emit('reloadFactors',  {profile: this.profile});
      }

      // display notification (if any).
      if(notification) {
       this.$emit('notify', notification);
      }

      // if we did a force enrollment or we bypassed confirmation due to re-enrollment
      // of the same number, then we can dismiss the dialog.
      if(this.forceEnrollment || bypassConfirmation) {
        this.resetComponentData();
        this.dialogMFAEnroll = false;
        this.mfaEnrollInProgress = false;
      }
    },
    async enrollFactorConfirm() {
      // startup the spinner on the confirm button.
      this.mfaEnrollInProgress = true;

      const {status, notification, factor} = await this.$cvApi.factorActivate(this.profile.id, this.enrollmentFactorId, this.callerResponse);

      // if we were successful reload the factors.
      if(status && factor) {
        // notify the parent component to refresh the factors from the backend.
        this.$emit('reloadFactors',  {profile: this.profile});

        // enrollment successful, close the dialog.
        this.resetComponentData();
        this.dialogMFAEnroll = false;
      }
      else {
        // failure without notification, incorrect code on enrollment.
        if(notification == null) {
            this.callerEnrolled = false; // flag the input field to show a red X
            this.enrollmentMessage = $gettext('Failed to activate factor:  The supplied code was invalid.');
        }
      }

      // display notification (if any)
      if(notification) {
        this.$emit('notify', notification);
      }

      // stop the confirm spinner regardless of enrollment attempt result.
      this.mfaEnrollInProgress = false;
    }
  }
}
</script>
