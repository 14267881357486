<template>
  <!-- MFA Management Chooser Dialog -->
  <v-row justify="center">
    <v-dialog v-model="dialogManageMFA" persistent max-width="625">
      <v-card>
        <v-card-title>
          <span v-translate='{caller: callerName}'>Choose a factor to manage for: "%{ caller }"</span>
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" width="30%" v-translate>Factor description</th>
                <th class="text-left ma-0 pa-0" width="1%"></th>
                <th class="text-right">
                  <!-- Always include header, but only put text in it if Single Reset or Enroll is enabled -->
                  <div
                    v-if="featureFlags.enableFactorResetSingle ||
                          featureFlags.enableFactorEnrollment"
                    v-translate
                  >
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(factor, factorId) in manageableFactors" :key="factorId">
                <td v-translate='{factorDescription: $cvutils.getTranslatedFactorDesc(factor)}'>%{ factorDescription }</td>
                <td class="ma-0 pa-0">
                  <v-tooltip v-if="factor.profile.tooltip" right>
                    <template v-slot:activator="{on, attrs}">
                      <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{ factor.profile.tooltip }}</span>
                  </v-tooltip>
                </td>
                <!-- factor is is pending or active, we want the column regardless for consistency -->
                <td v-if="factor.status !== 'NOT_SETUP'" class="text-right">
                  <!-- if the factor is in a pending state, add a chip/tooltip to highlight that -->
                  <v-tooltip v-if="factor.status === 'PENDING_ACTIVATION'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind:class="{'ma-2': featureFlags.enableFactorResetSingle}"
                        v-on="on"
                        v-bind="attrs"
                        medium
                        :id="'manage_pending_'+factor.type+'_'+factor.provider"
                        color="info"
                      >
                        <translate>PENDING ACTIVATION</translate>
                      </v-chip>
                    </template>
                    <span><translate>This factor is pending activation</translate></span>
                  </v-tooltip>
                  <!-- display a reset button if the feature flag is enabled, state could be pending or active -->
                  <v-btn
                    v-if="featureFlags.enableFactorResetSingle"
                    small
                    :id="'manage_reset_'+factor.type+'_'+factor.provider"
                    color="success"
                    @click="openManageMFADialog('mfa-reset-single', factor)"
                  >
                    <translate>Reset</translate>
                  </v-btn>
                </td>
                <!-- if enrollment is enabled, and the factor isn't setup, display an enroll button -->
                <td v-else-if="featureFlags.enableFactorEnrollment && factor.status === 'NOT_SETUP'" class="text-right">
                  <v-btn
                    :id="'manage_enroll_'+factorId"
                    small
                    color="success"
                    @click="openManageMFADialog('mfa-enroll', factor)"
                  >
                    <translate>Enroll</translate>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!--  FIXME KDK: figure out a way to do this where this component is in the table and not a dangler -->
        <EnrollMFA
          v-model="dialogMFAEnroll"
          :profile="profile"
          :factor="selectedFactor"
          :featureFlags="featureFlags"
          @close="closeManageMFADialog"
          @notify="$emit('notify', $event)"
          @reloadFactors="$emit('reloadFactors', $event)"
        />

        <!-- FIXME KDK: figure out a way to do this where this component is in the table and not a dangler -->
        <ResetMFASingle
          v-model="dialogMFAResetSingle"
          :profile="profile"
          :factor="selectedFactor"
          @close="closeManageMFADialog"
          @notify="$emit('notify', $event)"
          @reloadFactors="$emit('reloadFactors', $event)"
        />

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <ResetMFAAll
            v-model="dialogMFAResetAll"
            :profile="profile"
            :factorList="resetableFactors"
            @close="closeManageMFADialog"
            @notify="$emit('notify', $event)"
            @reloadFactors="$emit('reloadFactors', $event)"
          />
          <!-- FIXME KDK: can we make the button part of the ResetMFAAll Component? -->
          <v-btn v-if="featureFlags.enableFactorResetAll && Object.keys(activeAndPendingFactors).length > 0"
            color="success"
            id="manage_resetall"
            @click="openManageMFADialog('mfa-reset-all')"
          >
            <translate>Reset All</translate>
          </v-btn>
          <v-btn
            id="manage_cancel"
            color="primary"
            @click="dialogManageMFA = false"
          >
            <translate>Cancel</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

import EnrollMFA from '@/components/ActionDialogs/EnrollMFA'
import ResetMFAAll from '@/components/ActionDialogs/ResetMFAAll'
import ResetMFASingle from '@/components/ActionDialogs/ResetMFASingle'

export default {
  name: 'ManageMFA',

  components: {
    ResetMFAAll,
    ResetMFASingle,
    EnrollMFA
  },


  // Component properties
  //
  // profile - project object for the selected user.
  // value   - controls if the dialog is visible or hidden.
  //
  props: {
    profile: Object,
    featureFlags: Object,
    idpDetails: Object,
    value: Boolean
  },

  data: () => ({
    // selected factor to manage
    selectedFactor: {},

    // MFA Management Dialog Display Controls
    dialogMFAResetSingle: false,
    dialogMFAResetAll: false,
    dialogMFAEnroll: false,
  }),

  computed: {
    // expose a computed dialog parameter that returns the :value property
    // of this component on retrieval and emits an close event to the parent
    // to close the dialog when set to 'false'.
    dialogManageMFA: {
      get() {
        return this.value;
      },
      set(value) {
        if(!value) {
          this.$emit('close', 'mfa-manage');
        }
      }
    },
    // FIXME: we should make this a return property from our API call so we don't have to do this.
    callerName() {
      if(this.profile && this.profile.profile) {
        return this.profile.profile.firstName + ' ' + this.profile.profile.lastName;
      }
      else {
        return "";
      }
    },
    manageableFactors() {
      return this.manageFactorsList(true);
    },
    resetableFactors() {
      return this.manageFactorsList(false);
    },
    // filter factors to only active and pending for the selected customer
    activeAndPendingFactors() {
      let activeAndPending = {};
      // make sure we have a valid profile and MFA object first and foremost.
      if(this.profile && this.profile.mfa) {
        // loop over all factors, and filter out active and pending factors.
        for(let key in this.profile.mfa.factors) {
          let factor = this.profile.mfa.factors[key];
          if(factor.status == 'ACTIVE' || factor.status == 'PENDING_ACTIVATION') {
            activeAndPending[factor.id] = factor;
          }
        }
      }
      return activeAndPending;
    },
  },
  methods: {
    manageFactorsList(includeEnrollable) {
      let factorsList = {};
      // make sure we have a valid profile and MFA object first and foremost.
      if(this.profile && this.profile.mfa) {
        // loop over the list of factors for this caller, so we can merge
        // Okta Verify Push + Okta Verify TOTP into a single factor for reset.
        for(let key in this.profile.mfa.factors) {
          let factor = this.profile.mfa.factors[key];
          // if we have the Okta Verify Push factor, keep it but change the description.
          if((factor.provider === 'OKTA') && (factor.type === 'push')) {
              // make a deep clone so we can safely change the description without
              // impacting the source data.
              let tempFactor = cloneDeep(factor);
              tempFactor.description = 'Okta Verify';
              factorsList[tempFactor.id] = tempFactor;
          }
          // For Okta OIE we can't manage the email factor anymore -- so keep it out of the list.
          else if(factor.type == 'email' && (this.idpDetails.type == 'okta' && this.idpDetails.version == 'oie')) {
            continue;
          }
          // filter out device factor types from the manageable factor list.
          else if(factor.type == 'device') {
            continue;
          }
          // if we have Okta Verify TOTP just skip it as we are tieing to to push for reset.
          else if(factor.provider === 'OKTA' && (factor.type === 'token:software:totp')) {
            continue;
          }
          // skip enrollable factors if we don't want them (this is for the ResetAll list)
          else if(factor.status === 'NOT_SETUP' && !includeEnrollable) {
            continue;
          }
          else {
            factorsList[factor.id] = factor;
          }
        }
      }
      return factorsList;
    },
    openManageMFADialog(dialog, selectedFactor) {
      this.selectedFactor = selectedFactor;

      // open the specified MFA management dialog.
      switch (dialog) {
        case 'mfa-reset-single':
          // Make a deep clone of the selected factor for the single reset case as we
          // might have to modify the description without impacting the source.
          this.selectedFactor = cloneDeep(selectedFactor);

          // if they are resetting Okta verify we need to update description for the UI.
          if(this.selectedFactor.provider === 'OKTA' && this.selectedFactor.type == 'push') {
            // rewrite description for the Reset UI
            this.selectedFactor.description = 'Okta Verify';
          }

          this.dialogManageMFA = false;
          this.dialogMFAResetSingle = true;
          break;
        case 'mfa-reset-all':
          this.dialogManageMFA = false;
          this.dialogMFAResetAll = true;
          break;
        case 'mfa-enroll':
          this.dialogManageMFA = false;
          this.dialogMFAEnroll = true;
          break;
        default:
          break;
      }
    },
    closeManageMFADialog(dialog) {
      // open the specified action dialog.
      switch (dialog) {
        case 'mfa-reset-single':
          this.dialogMFAResetSingle = false;
          break;
        case 'mfa-reset-all':
          this.dialogMFAResetAll = false;
          break;
        case 'mfa-enroll':
          this.dialogMFAEnroll = false;
          break;
        default:
          break;
      }
    },
  }
}
</script>
