<template>
  <v-row justify="center">

    <!-- Reset Single Factor Confirmation Dialog -->
    <v-dialog
      v-model="dialogMFAResetSingle"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title>
          <span v-translate='{caller: callerName}'>Reset factor for: "%{ caller }"</span>
        </v-card-title>
        <v-card-text>
          <translate>Are you sure you want to reset the following factor for the selected profile?</translate>
          <v-list dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-circle-medium</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title id="cv_reset_factor_desc" v-translate='{factorDescription: $cvutils.getTranslatedFactorDesc(factor)}'>%{ factorDescription }</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-subtitle>
          <v-alert type="info" dense>
            <strong><translate>Please Note:</translate> </strong>
            <translate>The caller must also remove the reset factor from the registered device(s).</translate>
          </v-alert>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <!-- confirmation button -->
          <v-btn
            id="manage_reset_confirm"
            color="success"
            @click="resetFactor()"
            :loading="mfaResetInProgress"
          >
            <translate>Confirm</translate>
          </v-btn>

          <!-- cancel button -->
          <v-btn
            id="manage_reset_cancel"
            color="primary"
            @click="dialogMFAResetSingle = false"
            :disabled="mfaResetInProgress"
          >
            <translate>Cancel</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'ResetMFASingle',

  // Component properties
  //
  // profile - project object for the selected user.
  // value   - controls if the dialog is visible or hidden.
  //
  props: {
    profile: Object,
    factor: Object,
    value: Boolean
  },

  computed: {
    // expose a computed dialog parameter that returns the :value property
    // of this component on retrieval and emits an close event to the parent
    // to close the dialog when set to 'false'.
    dialogMFAResetSingle: {
      get() {
        return this.value;
      },
      set(value) {
        if(!value) {
          this.$emit('close', 'mfa-reset-single');
        }
      }
    },
    // FIXME: we should make this a return property from our API call so we don't have to do this.
    callerName() {
      if(this.profile && this.profile.profile) {
        return this.profile.profile.firstName + ' ' + this.profile.profile.lastName;
      }
      else {
        return "";
      }
    }
  },

  data: () => ({
    // Control for reset in progress spinner.
    mfaResetInProgress: false,
  }),
  methods:  {
    resetFactor: async function() {
      // Enable the progress indicator
      this.mfaResetInProgress = true;

      const factorId = this.factor.id;
      const factor_desc = await this.$cvutils.getTranslatedFactorDesc(this.factor);
      const {status, notification} = await this.$cvApi.factorResetSingle(this.profile.id, factorId);

      if(status) {
        // notify the parent component to refresh the factors from the backend.
        this.$emit('reloadFactors',  {profile: this.profile});
      }

      // display any notifications to the caller.
      if(notification) {
        let src = notification.body;
        if(src == 'Factor does not exist for caller'){
          // when the message is the general "Factor does not exist for caller", replace the message with details
          // otherwise just directly post the notification
          let message = 'Factor: %{ factordesc } does not exist for caller: %{ callerName }';
          var variables = {factordesc: factor_desc, callerName: this.callerName};
          notification.variables = variables;
          notification.body = message;
        }
        this.$emit('notify', notification);
      }

      // Stop the in progress indicator
      this.mfaResetInProgress = false;
      this.dialogMFAResetSingle = false;
    }
  }
}
</script>
