<template>
  <v-menu v-if="authState && authState.isAuthenticated && profile && profile.name"
    bottom
    min-width="200px"
    rounded
    offset-y
    v-model="menuopen"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
        <div>
          <v-btn id="cv_user_profile" icon x-large v-on="on">
            <v-avatar color="primary" size="48">
                <span class="white--text text-h5">{{ callerInitials }}</span>
            </v-avatar>
            <!--
            <v-icon v-if="value">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
            -->
          </v-btn>
          <v-btn id="cv_user_profile_chevron" icon small v-on="on">
            <v-icon v-if="menuopen">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title id="cv_user_profile_name">
            {{ profile.name }}
          </v-list-item-title>
          <v-list-item-subtitle id="cv_user_profile_email">
            {{ profile.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        id="cv_dark_switch_li"
        link
        @click="isDark = !isDark"
      >
        <v-list-item-icon>
            <v-icon>mdi-theme-light-dark</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0">
                <translate>Dark theme</translate>
              </v-col>
              <v-col align="right" class="ma-0 pa-0">
                <v-switch disabled v-model="isDark" style="width: 0" class="pr-12 ma-0" hide-details="true" id="cv_dark_switch"/>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <LanguageSelector />
      <v-divider></v-divider>
      <v-list-item
        id="cv_logout_button"
        link
        @click="$emit('logout'); menuopen = false;"
      >
        <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <translate>Logout</translate>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import LanguageSelector from '@/components/LanguageSelector';

  export default {
    name: 'UserProfileMenu',

    components: {
      LanguageSelector,
    },

    props: {
      profile: Object
    },
    data: () => ({
      menuopen: false
    }),
    computed: {
      isDark: {
        get: function() {
          return this.$vuetify.theme.dark
        },
        set: function(value) {
          // set the light/dark theme based on what the value.
          this.$vuetify.theme.dark = value;

          // keep track of current setting for theme in a cookie.
          if (this.$vuetify.theme.dark) {
            this.$cookies.set('verifyIsDark', true);
          } else {
            this.$cookies.remove('verifyIsDark');
          }
        }
      },
      callerInitials() {
        let initials = '';
        if(this.profile && this.profile) {
          if(this.profile.given_name.length > 0) {
            initials += this.profile.given_name[0];
          }
          if(this.profile.family_name.length > 0) {
            initials += this.profile.family_name[0];
          }
          return initials;
        }
        return initials.length > 0 ? initials : '??';
    }
    },
    methods: {
    }
  }
</script>
